// state

const baseState = {
    filters: {
      pagination: {
        page: 1,
        pages: 1,
        limit: 10
      },
      expandTable: false,
      searchFilter: {
        searchValue: '',
        options: [
          {
            text: 'Phone',
            value: 'phone_number'
          }
        ],
        optionValue: ''
      },
      sortFilter: {
        options: [
          {
            text: 'Fecha creación',
            value: 'created_at'
          },
        ],
        optionValue: 'created_at',
        sortModes: [
          {
            text: 'Ascendente',
            value: 1
          },
          {
            text: 'Descendente',
            value: -1
          }
        ],
        sortModeValue: -1,
      },
      bankAutoComplete: {
        value: [],
        options: [],
        selectAll: false
      },
      typeAccountFilter: {
        options: [
          {
            text: 'Cuenta corriente',
            value: 'Corriente'
          }, 
          {
            text: 'Cuenta ahorro',
            value: 'Ahorro'
          }
        ],
        optionValue: '',
      },
    }
  };
  
  const state = baseState;
  
  const mutations = {
    SET_FILTERS(state, filters) {
      state.filters = { ...state.filters, ...filters };
    }
  };
  
  const actions = {
    setFilters({ commit }, user) {
      commit('SET_FILTERS', user);
    },
    resetFilters({ commit }) {
      commit('SET_FILTERS', baseState);
    }
  };
  
  const getters = {
    filters: (state) => state.filters
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  };
  
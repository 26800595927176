module.exports = [
  {
    path: '/pago-movil',
    name: 'pago-movil',
    meta: {
      requiresAuth: true,
      permission: 'refund/transaction',
      title: 'Pago movil',
      module: 'pago-movil',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-car-select',
      isBaseLayout: true
    },
    component: () =>
      import(
        /* webpackChunkName: "pago-movil" */ '@/views/pago-movil/PagoMovilLayout.vue'
      ),
    children: [
      {
        path: 'reembolso',
        name: 'pago-movil-refund',
        meta: {
          requiresAuth: true,
          permission: 'refund/transaction',
          title: 'Reembolso',
          module: 'pago-movil',
          layout: 'admin',
          adminLayoutNav: true,
          isBaseLayout: true,
          icon: 'mdi-cash-refund',
        },
        component: () =>
          import(
            /* webpackChunkName: "pago-movil-refund" */ '@/views/pago-movil/PagoMovilRefundList.vue'
          )
      },
      {
        path: 'transactions-list',
        name: 'pago-movil-transactions',
        meta: {
          requiresAuth: true,
          permission: '',
          title: 'Transacciones',
          module: 'pago-movil',
          layout: 'admin',
          adminLayoutNav: true,
          icon: 'mdi-bank-transfer',
        },
        component: () =>
          import(
            /* webpackChunkName: "pago-movil-transactions" */ '@/views/pago-movil/PagoMovilTransactionList.vue'
          )
      },
      {
        path: 'bank-list',
        name: 'pago-movil-bank-list',
        meta: {
          requiresAuth: true,
          permission: 'refund/transaction',
          title: 'Pago moviles asociados',
          module: 'pago-movil',
          layout: 'admin',
          adminLayoutNav: true,
          isBaseLayout: true,
          icon: 'mdi-checkbook',
        },
        component: () =>
          import(
            /* webpackChunkName: "pago-movil-refund" */ '@/views/pago-movil/PagoMovilConfig.vue'
          )
      }
    ]
  }
];
